.revenue_filters {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.revenue_filters .filter_dropdown_option {
    width: 18%;
}

.stripe_revenue_graph_container {
    width: 100%;
    padding: 4% 10%;
    display: flex;
    justify-content: center;
}