.revamped__ticket__container {
    display: flex;
    flex-direction: row;
    column-gap: 1%;
    /* align-items: center; */
    justify-content: center;
}

.revamped__ticket__container .revamped__type__ticket__container {
    width: 20%;
    height: 70vh;
    border: 3px solid rgb(214, 213, 213);
    /* overflow-y: scroll; */
}

.revamped__ticket__container .revamped__type__ticket__container .revamped__ticket__header {
    text-align: center;
    padding: 5px;
    font-weight: bold;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    border-bottom: 1px solid rgb(156, 155, 155);
    padding-bottom: 10px;
}

.revamped__ticket__container .revamped__type__ticket__container .revamped__tickets__list {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    height: 65vh;
    overflow-y: scroll;
}

.revamped__ticket__container .revamped__type__ticket__container .revamped__tickets__list .each__revamped__ticket {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgb(156, 155, 155);
    padding-top: 10px;
    padding-bottom: 10px;
}

.revamped__ticket__container .revamped__type__ticket__container .revamped__tickets__list .each__revamped__ticket .revamped__icon {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.revamped__ticket__container .revamped__type__ticket__container .revamped__tickets__list .each__revamped__ticket .each__revamped__body {
    width: 80%;
}

.revamped__ticket__container .revamped__type__ticket__container .revamped__tickets__list .each__revamped__ticket .revamped__icon img {
    height: 30px;
}