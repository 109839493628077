.outerGrid {
    position: relative;
}

.deleteIcon {
    position: absolute;
    top: 50%;
    right: 10px;
}

.deleteIconImage {
    position: absolute;
    top: 30px;
    right: 10px;
}

.deleteIconPoll {
    position: absolute;
    top: 50%;
    right: 10px;
}