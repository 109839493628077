.main__ticket__container {
    display: flex;
    width: 100%;
    white-space: pre-line;
    background-color: #f5f5f5;
}

.main__ticket__container .right__side__history {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    padding-top: 30px;
    padding-bottom: 50px;
    width: 20%;
    /* background-color: rgb(239, 242, 245); */
    border-left: 2px solid rgb(193, 193, 193);
    text-wrap: wrap;
    margin-top: 10px;
    /* z-index: -1; */
}

.main__ticket__container .right__side__history .each__historical__ticket {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 0 1px #585655, 0 10px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 5px;
    width: 90%;
    border-radius: 5px;
    font-size: 1rem;
}

.main__ticket__container .right__side__history .current__ticket {

    box-shadow: 0 0 0 2.5px #4291c9, 0 10px 10px 0 rgba(0, 0, 0, 0.1);
}

.main__ticket__container .right__side__history .each__historical__ticket:hover {
    cursor: pointer;
}

.ticket__view__container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    /* justify-content: center; */
    margin: 20px 3vw 100px 3vw;
    font-size: 17px;
    padding-bottom: 400px;
    width: 80%;
    background-color: rgb(245, 245, 245);
}

.ticket__view__container .ticket__heading {
    font-size: 30px;
    margin-top: 1vh;
    margin-bottom: 2vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat__container {
    width: 100%;
    white-space: pre-line;
}

.ticket__view__container .ticket__description {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    min-width: 100%;
}

.ticket__view__container .ticket__description .text__description__text {
    width: 70%;
    position: relative;
}

.ticket__view__container .ticket__description .text__description__text .conversation__time {
    font-weight: 900;
}

.ticket__view__container .ticket__description .text__description__text {
    box-shadow: 0 0 0 0.4px #339ee1, 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
}

.ticket__view__container .ticket__description .text__description__text img {
    position: absolute;
    left: -70px;
    bottom: 0;
    height: 50px;
}

.ticket__view__container .ticket__description__support {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
    min-width: 100%;
}

.ticket__view__container .ticket__description__support .text__description__text__support img {
    position: absolute;
    right: -70px;
    bottom: 0;
    height: 50px;
}

.ticket__view__container .ticket__description__support .text__description__text__support {
    width: 70%;
    position: relative;
    text-align: left;
}

.ticket__view__container .ticket__description__support .text__description__text__support {
    box-shadow: 0 0 0 0.4px #d86c14, 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
}

.ticket__view__container .ticket__description__support .text__description__text__support .conversation__time {
    font-weight: 900;
    text-align: right;
}

/* ------------------------------------------------------------------------ */


.ticket__view__container .ticket__description__image {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
    min-width: 100%;
}

.ticket__view__container .ticket__description__image img {
    max-height: 400px;
    object-fit: contain;
}

.ticket__view__container .ticket__description__image video {
    max-height: 400px;
    object-fit: contain;
}

.ticket__view__container .ticket__description__image .text__description__text__image__user {
    width: 70%;
    position: relative;
    text-align: left;
}

.ticket__view__container .ticket__description__image .text__description__text__image__support {
    width: 70%;
    position: relative;
    text-align: right;
}

.ticket__view__container .ticket__description__image .text__description__text__image__support img {
    margin-left: auto;
}

.ticket__view__container .ticket__description__image .text__description__text__image__support video {
    margin-left: auto;
}

.ticket__view__container .ticket__description__image .text__description__text__image__user {
    box-shadow: 0 0 0 0.4px #339ee1, 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
}

.ticket__view__container .ticket__description__image .text__description__text__image__support {
    width: 70%;
    position: relative;
    text-align: right;
}

.ticket__view__container .ticket__description__image .text__description__text__image__support {
    box-shadow: 0 0 0 0.4px #d86c14, 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
}

.ticket__view__container .ticket__description__image .text__description__text__image__user .conversation__time {
    margin-top: 30px;
    font-weight: 900;
    text-align: left;
}

.ticket__view__container .ticket__description__image .text__description__text__image__support .conversation__time {
    margin-top: 30px;
    font-weight: 900;
    text-align: right;
}


.ticket__view__container .ticket__description__internal__above {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
    min-width: 100%;
}

.ticket__view__container .ticket__description__internal__above .ticket__description__internal__image__note img {
    max-height: 400px;
    object-fit: contain;
    background-color: #e6e68d;
}

.private__note__image__container {
    background-color: #e6e68d;
}
/* 
.ticket__view__container .ticket__description__internal .text__description__text__internal {
    box-shadow: 0 0 0 1px #e6e68d, 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
    background-color: #e6e68d;
} */
/* ---------------------- */

.ticket__view__container .ticket__description__internal {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
    min-width: 100%;
}

.ticket__view__container .ticket__description__internal .text__description__text__internal img {
    position: absolute;
    right: -70px;
    bottom: 0;
    height: 40px;
}

.ticket__view__container .ticket__description__internal .text__description__text__internal {
    width: 70%;
    position: relative;
    text-align: left;
}

.ticket__view__container .ticket__description__internal .text__description__text__internal {
    box-shadow: 0 0 0 1px #e6e68d, 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
    background-color: #e6e68d;
}

.ticket__view__container .ticket__description__internal .text__description__text__internal .conversation__time {
    font-weight: 900;
    text-align: right;
}

/* ------------------------------------------------------------------------------ */

.ticket__view__container .ticket__attachment__container {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    min-width: 100%;
}

/* -------------------------------------------------------------------------------- */

.ticket__view__container .reply__container {
    width: 100%;
}

.ticket__view__container .reply__container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
    width: 70%;
}

.ticket__view__container .reply__container .text__description__text__reply {
    width: 100%;
}

.ticket__view__container .reply__container img {
    position: absolute;
    right: -80px;
    bottom: 10px;
    height: 40px;
}

.ticket__view__container .reply__container img:hover {
    cursor: pointer;
    height: 50px;
}

/* ----------------------------------------------------------------------------------- */

.ticket__view__container .time__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 70%;
    column-gap: 20px;
}


.ticket__view__container .time__container_2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    width: 70%;
    column-gap: 20px;
    font-weight: 600;
}

.ticket__view__container .time__container img {
    height: 50px;
}

.ticket__view__container .ticket__assignedTo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    width: 70%;
}

.ticket__view__container .ticket_assignment_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    width: 70%;
}

.ticket__view__container .ticket__assets {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    column-gap: 50px;
}

.ticket__view__container .ticket__assets img {
    height: 80px;
}

.ticket__view__container .ticket__assets img:hover {
    cursor: pointer;
}

.ticket__view__container .notes__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    width: 70%;
}

.ticket__view__container .notes__container .each__note {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
}

.ticket__view__container .button__container {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    column-gap: 20px;
}

.ticket__view__container .normal__heading {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.ticket__view__container .history__icon {
    height: 30px;
    margin: 10px;
}

.small__icon img {
    height: 70px;
}


.animated-icon-container {
    position: fixed;
    bottom: 30px;
    left: 30px;
    animation: slideIn 1s ease-out;
    cursor: help;
}

@keyframes slideIn {
    from {
        left: -100px;
    }

    to {
        left: 30px;
    }
}

.upload__container {
    /* background-color: #339ee1; */
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}