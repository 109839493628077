.campaign__list__container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.campaign__list__container_2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem;
}