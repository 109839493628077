@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap');

.developerprojects__first {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 0.2rem;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    font: normal normal 300 1em/1.55em 'Outfit', sans-serif;
    background-color: white;
    color: white;
}

@media screen and (max-width: 1024px) {
    .developerprojects__first {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .developerprojects__first {
        grid-template-columns: repeat(1, 1fr);
    }
}


.card-container {
    width: 100%;
    margin: 1em auto;
    border-radius: 15px;
    margin-bottom: 1rem;
    padding: 0.7rem 1.5rem;
}

div.flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.coin-base,
.time-left,
.card-attribute {
    display: flex;
    align-items: center;
    padding: 0.6em 0;
}

.card-attribute {
    border-top: 1px solid hsl(0, 0%, 100%);
}

div.attribution {
    margin: 0 auto;
    width: 100%;
    font: var(--var-para);
    text-align: center;
    padding: 1.5em 0 1.5em 0;
    color: white
}

.attribution a {
    color: white;
}