$darkColor: rgb(31, 31, 31);
$lightColor: rgb(224, 224, 224);
$primary-color: #0074d9;
$secondary-color: #ff851b;

// Mixins
@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

// ----------------------------------------------------------------------------------------------------------
// ---------------------- PUSH NOTIFICATION -----------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------
.campaign__container {
    @include flex-column-center;
    margin-top: 50px;
    margin-bottom: 300px;
    row-gap: 30px;
    width: 100%;

    .campaign__heading {
        font-size: 50px;
    }

    .event__selection__container {
        @include flex-column-center;
        row-gap: 20px;
        width: 100%;
    }

    .steps__container img {
        height: 90px;
        margin: 30px;
        rotate: 180deg;
    }
}


// ----------------------------------------------------------------------------------------------------------
// ---------------------- LOGIN -----------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------

// ----------------------------------------------------------------------------------------------------------
// ---------------------- LEARNINGS -------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------

.container {
    max-width: 1200px;
    margin: 0 auto;
}

// Extending Selectors
.button {
    padding: 10px 20px;
    background-color: $secondary-color;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: darken($secondary-color, 10%);
    }
}
