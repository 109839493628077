@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800;900&display=swap");

*,
*:after,
*:before {
    box-sizing: border-box;
}

.main-div-snippets {
    font-family: "Lexend", sans-serif;
    line-height: 1.5;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: #393232;
}

img {
    display: block;
}

.card-list-snippets {
    width: 60%;
}

.card-snippets {
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 20px 50px 0 rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: hidden;
    padding: 1.25rem;
    position: relative;
    transition: 0.15s ease-in;
    margin: 10px;
}

.card-snippets:hover,
.card-snippets:focus-within {
    box-shadow: 0 0 0 2px #16c79a, 0 10px 60px 0 rgba(0, 0, 0, 0.1);
    /* transform: translatey(-5px); */
}

.card-image-snippets {
    border-radius: 10px;
    overflow: hidden;
}

.card-header-snippets {
    display: flex;
    flex-direction: row;
}

.card-header-snippets a {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.25;
    padding-right: 1rem;
    text-decoration: none;
    color: inherit;
    will-change: transform;
}

.card-header-snippets a:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.icon-button-snippets {
    border: 0;
    background-color: #fff;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: 1.25rem;
    transition: 0.25s ease;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
    z-index: 1;
    cursor: pointer;
    color: #565656;
}

.icon-button-snippets svg {
    width: 1em;
    height: 1em;
}

.icon-button-snippets:hover,
.icon-button-snippets:focus {
    background-color: #ec4646;
    color: #fff;
}

.card-footer-snippets {
    margin-top: 1rem;
    border-top: 1px solid #ddd;
    padding-top: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.card-meta-snippets {
    display: flex;
    align-items: center;
    margin-right: 25px;
    color: #787878;
}

.card-meta-snippets svg {
    flex-shrink: 0;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.25em;
}

.tick-snippets {
    position: absolute;
    right: 20px;
}

.tick-snippets svg {
    cursor: pointer;
    color: #16c79a;
}

.tick-snippets svg:hover {
    scale: 1.3;
    color: #ec4646;
}

h1 {
    text-align: center;
    font-size: 50px;
}

.snippets__header {
    font-weight: 600;
}

.filter__bar__companies {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.svg-icon {
    width: 1em;
    height: 1em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
    fill: #434343;
}

.svg-icon circle {
    stroke: #434343;
    stroke-width: 1;
}

@media screen and (max-width: 1300px) {
    .main-div-snippets {
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    .main-div-snippets {
        flex-direction: column;
    }
}
