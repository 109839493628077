body {
    height: 100%;
}

.gridBox {
    cursor: pointer;
}

.gridBox:hover {
    transform: scale(1.02);
}

.header-top {
    font-size: 50px;
    text-align: center;
}

.homepage {
    min-height: 100vh;
    background-color: rgb(246, 246, 246);
}