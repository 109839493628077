.newRank {
    width: 110px;
}

.currentRank {
    width: 55px;
}

.currentLanguage {
    text-align: center;
    width: 85px;
}

.sendIcon {
    color: blue;
}