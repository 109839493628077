.ticketfeedback__container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ticketfeedback__data__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.campaign__list__container__feedback {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 8rem;
}