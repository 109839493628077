.tickets__history__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    margin-top: 50px;
    margin-bottom: 400px;
}

.tickets__history__container .ticket__container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    box-shadow: 0 0 0 1px #494c57, 0 10px 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 90%;
    padding: 15px;
}

.tickets__history__container .ticket__container .each__ticket__container {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
}